@import "../../themes/generated/variables.base.scss";

.user-info {
    display: flex;
    align-items: center;

    .dx-toolbar-menu-section & {
        padding: 10px 6px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .image-container {
        overflow: hidden;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        margin: 0 4px;
        /*border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0,0,0,0.15);*/
        .user-image {
            display: none;
            width: 100%;
            height: 100%;
            background: url("https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png") no-repeat #fff;
            background-size: cover;
        }
    }

    .user-name {
        font-size: 14px;
        color: $base-text-color;
        margin: 0 9px;
    }
}

.user-panel {
  .dx-list-item .dx-icon {
    vertical-align: middle;
    color: $base-text-color;
    margin-right: 16px;
  }
  .dx-rtl .dx-list-item .dx-icon {
    margin-right: 0;
    margin-left: 16px;
  }
}

.dx-context-menu.user-menu.dx-menu-base {
    &.dx-rtl {
        .dx-submenu .dx-menu-items-container .dx-icon {
            margin-left: 16px;
        }
    }

    .dx-submenu .dx-menu-items-container .dx-icon {
        margin-right: 16px;
    }

    .dx-menu-item .dx-menu-item-content {
        padding: 3px 15px 4px;
        min-width: 150px;
    }
}

.dx-context-menu .dx-menu-items-container {
    padding: 4px 0;
    font-size: 14px !important;
}

.dx-theme-generic .user-menu .dx-menu-item-content .dx-menu-item-text {
  padding-left: 4px;
  padding-right: 4px;
}

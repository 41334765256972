.app {
    @import "./themes/generated/variables.base.scss";
    /*background-color: darken($base-bg, 5.00);*/
    background-color: $base-bg;
    display: flex;
    height: 100%;
    width: 100%;
}

.borderBottom {
    border-bottom: 1px solid #c6c6c6;
}

.border {
    border: 1px solid #c6c6c6;
}

.width100 {
    width: 100%;
}

.white {
    background-color: white;
}

.silver {
    background-color:#E9E9E9;
}


.paddings {
    padding-left: 40px;
    padding-right: 40px;
}

.sizeLimit {
    max-width: 1200px;
}

.headerTable {
    height:60px;
    min-height: 61px;
}

.dialog.headerTable {
    top: 0px;
    z-index: 1000;
}

.vertical.headerTable {
    white-space: nowrap;
    padding-left: 38px;
    padding-right: 38px;
    margin: 0px;
}

.xafAlignCenter {
    margin: 0 auto;
}

.sizeLimit {
    max-width: 1200px;
    margin:auto;
}

.loginPanel {
    max-width:600px
}

.text-center {
    text-align:center;
}


.logincontainer {
    display: flex;
    background-color: #F2F2F2;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .loginsection {
        flex-grow: 1;
        display: flex;
        align-items: center;
        padding: 20px 0 20px 0;
        justify-content: center;
    }

    .messageBrowser {
        margin-top: 40px;
        background-color: #fff !important;
        max-width: 80% !important;
    }
}

.eprlogo{
    height:25px;
}
.sbclogo {
    
}

@media (min-width: 2560px) {

}
@media (min-width: 1920px) and (max-width: 2559px) {

}
@media (min-width: 1366px) and (max-width: 1919px) {

}

@media (min-width: 768px) and (max-width: 1365px) {
    .eprlogo {
        height: 21px;
    }

    .sbclogo {
        width: 180px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .eprlogo {
        height: 21px;
    }

    .sbclogo {
        width: 180px;
    }
}
@media (min-width: 320px) and (max-width: 480px) {
    .eprlogo {
        height: 19px;
    }
    .sbclogo {
        width:160px;
    }
}
@import "../../themes/generated/variables.base.scss";
@import "../../dx-styles.scss";

.header-component {
    flex: 0 0 auto;
    z-index: 1;
    /*1box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);*/
    margin-top: -0px;
    margin-bottom: -50px;

    .dx-toolbar-items-container {
        height: 46px
    }

    .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
        color: $base-accent;
    }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.dx-theme-generic {
    .dx-toolbar {
        padding: 10px 0;
    }

    .user-button > .dx-button-content {
        padding: 3px;
    }

    
}

.user-button {
    background-color: rgba(255, 255, 255, .60) !important;

    
}

.user-button.dx-button.dx-state-hover {
    background-color: #ebebeb !important;
}
.dx-button.dx-state-hover {
    background-color: #009DFF !important;
}
 



.side-nav-inner-toolbar {
  width: 100%;
}

.container {
    height: 100%;
    flex-direction: column;
    display: flex;

    .header-component {
        
    }
}

.layout-body {
  flex: 1;
  min-height: 0;
}

.content {
  flex-grow: 1;
}

#navigation-header {
    @import "../../themes/generated/variables.additional.scss";
    background-color: #F2F2F2;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    .toolbar-head {
        width: 248px;
    }

    .sbcounty-logo {
        background: transparent url('../../media/sb_cologo-full_color.png') no-repeat;
        background-size: cover;
        width: 180px;
        height: 100px;
        display: inline-block;
        margin-left: 30px;
        margin-top: 20px;
        transition: height, 0.3s linear;
    }

    .menu-button {
        float: left;
    }

    .menu-button .dx-icon {
        /*color: $base-text-color;*/
        color: silver !important;
    }

    .screen-x-small & {
        padding-left: 20px;
    }

    .dx-theme-generic & {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .header-title {
        font-size: 1.2rem;
        font-weight: bolder;
        color: #002D73;
        margin-top: -10px;
        padding-left: 12px;
        display: inline-block;
    }
}

@media (min-width: 2560px) {
}

@media (min-width: 1920px) and (max-width: 2559px) {
}

@media (min-width: 1366px) and (max-width: 1919px) {
}

@media (min-width: 768px) and (max-width: 1365px) {
}

@media (min-width: 481px) and (max-width: 767px) {
    #navigation-header {


        .toolbar-head {
            width: 248px;
        }

        .sbcounty-logo {
            background: transparent url('../../media/sb_cologo-full_color.png') no-repeat;
            background-size: cover;
            width: 140px;
            height: 80px;
            display: inline-block;
            margin-left: 30px;
            margin-top: 20px;
            transition: height, 0.3s linear;
        }

        .menu-button {
            float: left;
        }

        .menu-button .dx-icon {
            /*color: $base-text-color;*/
            color: silver !important;
        }

        .screen-x-small & {
        }

        .dx-theme-generic & {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .header-title {
            font-size: 1.2rem;
            font-weight: bolder;
            color: #002D73;
            margin-top: -10px;
            padding-left: 12px;
            display: inline-block;
        }
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    #navigation-header {
        

        .toolbar-head {
            
        }

        .sbcounty-logo {
            background: transparent url('../../media/sb_cologo-full_color.png') no-repeat;
            background-size: cover;
            height: 70px;
            display: inline-block;
            margin-left: 30px;
            margin-top: 20px;
            transition: height, 0.3s linear;
        }

        .menu-button {
            float: left;
        }

        .menu-button .dx-icon {
            /*color: $base-text-color;*/
            color: silver !important;
        }

        .screen-x-small & {
            padding-left: 20px;
        }

        .dx-theme-generic & {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .header-title {
            font-size: 1.2rem;
            font-weight: bolder;
            color: #002D73;
            margin-top: -10px;
            padding-left: 12px;
            display: inline-block;
        }
    }
}
@import "../../themes/generated/variables.base.scss";

.login-header {
    text-align: center;
    margin-bottom: 40px;

    .title {
        color: $base-accent;
        font-weight: bold;
        font-size: 30px;
        margin: 0;
    }

    .forgetCodeTitle {
        font-weight: normal;
        color: gray;
        font-size: 24px;
    }

    .forgetCodeInstruction {
        font-weight: 200;
        color: gray;
        font-size: 14px;
    }
}
.loginmessage {
    color: #007dff;
    font-weight:500;
}

.button-indicator {
    height: 32px;
    width: 32px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.eprdb {
    height: 60px;
}

.footerSection {
    .footerText {

    }
}

@media (min-width: 2560px) {
}

@media (min-width: 1920px) and (max-width: 2559px) {
}

@media (min-width: 1366px) and (max-width: 1919px) {
}

@media (min-width: 768px) and (max-width: 1365px) {
    .login-header {
        text-align: center;
        margin-bottom: 20px;

        .title {
            color: $base-accent;
            font-weight: bold;
            font-size: 30px;
            margin: 0;
        }

        .forgetCodeTitle {
            font-weight: normal;
            color: gray;
            font-size: 20px;
        }

        .forgetCodeInstruction {
            font-weight: 200;
            color: gray;
            font-size: 14px;
        }
    }

    .loginmessage {
        color: #007dff;
        font-weight: 500;
    }

    .button-indicator {
        height: 32px;
        width: 32px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
    }

    .eprdb {
        height: 44px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .login-header {
        text-align: center;
        margin-bottom: 20px;

        .title {
            color: $base-accent;
            font-weight: bold;
            font-size: 30px;
            margin: 0;
        }

        .forgetCodeTitle {
            font-weight: normal;
            color: gray;
            font-size: 20px;
        }

        .forgetCodeInstruction {
            font-weight: 200;
            color: gray;
            font-size: 14px;
        }
    }

    .loginmessage {
        color: #007dff;
        font-weight: 500;
    }

    .button-indicator {
        height: 32px;
        width: 32px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
    }

    .eprdb {
        height: 44px;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .login-header {
        text-align: center;
        margin-bottom: 20px;

        .title {
            color: $base-accent;
            font-weight: bold;
            font-size: 30px;
            margin: 0;
        }

        .forgetCodeTitle {
            font-weight: normal;
            color: gray;
            font-size: 20px;
        }

        .forgetCodeInstruction {
            font-weight: 200;
            color: gray;
            font-size: 14px;
        }
    }

    .loginmessage {
        color: #007dff;
        font-weight: 500;
    }

    .button-indicator {
        height: 32px;
        width: 32px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
    }

    .eprdb {
        height: 44px;
    }
}


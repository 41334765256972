

.content-block {
    padding-left: 20px;
    font-size: 1.1rem;

    .centrolpayroll {
        font-weight: bold;
        font-size: 2em;
    }

    .epayrolldropbox {
        font-size: 3em;
        border-bottom: 1px solid gray;
        color: gray;
    }

    .info {
        margin-top: 20px;
        margin-bottom: 20px;

        .nametitle {
            font-weight: bold;
            font-size: 1.4em;
            color: gray;
        }

        .nameexample {
            font-size: 1.2em;
            color: gray;
            font-weight: 200;
        }
    }

    #uploadPanel {
        background-color: #F8F8F8;
    }
}

.message{
    
}

.button-indicator {
    height: 32px;
    width: 32px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.dx-drawer-wrapper {
    background-color: #ffffff;

}

@media (min-width: 2560px) {
}

@media (min-width: 1920px) and (max-width: 2559px) {
}

@media (min-width: 1366px) and (max-width: 1919px) {
}

@media (min-width: 768px) and (max-width: 1365px) {
}

@media (min-width: 481px) and (max-width: 767px) {
}

@media (min-width: 320px) and (max-width: 480px) {
    .dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
        width: 40px !important;
    }
}

.dx-texteditor-input {
    font-size: 16px !important;
}

.dx-fileuploader-dragover .dx-fileuploader-input-container {
    
}
.dx-fileuploader-wrapper {
    background-color: #fff;
    border: 1px solid #f2f2f2;

    .dx-fileuploader-input-wrapper {
        padding: 7px 7px 7px 7px;

        .dx-fileuploader-input-container {
        }

        .dx-fileuploader-input-label {
            padding-left: 20px;
        }
    }
}

.fileList {
    .fileItem {
        padding: 4px 5px;
        
        .fileName {
            font-size:1.1em;
            font-weight:bold;
            text-transform:uppercase;
            margin-bottom:10px;
            .fileSize {
                padding-left:20px; 
                font-weight:normal;
                font-size:smaller;
                color: #c8c8c8;
            }
        }
    }
}

.errRow {
    margin-top: 12px;

    .errIcon {
        float: left;
        color: #ad0000;
        margin-right: 14px;
        font-size: 2rem;
    }

    .errText {
        font-size: 1.0rem;
        padding-top: 6px;

        .errMsg {
            font-size: 0.8em;
            font-style:italic;
            color: red;
        }
    }
}

.corrRow {
    margin-top:12px;

    .corrIcon {
        float: left;
        color: #058CFF;
        margin-right: 10px;
        font-size: 1.7rem;
    }

    .corrText {
        font-size: 1.0rem;
        padding-top: 6px;
    }
}

.message {
    color: gray !important;
}

.dx-texteditor.dx-editor-outlined {
        .dx-texteditor-input {
            padding: 8px 10px 6px;
            font-size: 0.9rem;
        }
    }

.docTypePanel {
    margin-bottom: 12px;

    .docTypeContainer {
        float: left;
        //doc type
        .docTypeText {
            font-size: 0.8rem;
            float: left;
            margin-right: 10px;
            margin-top: 9px;
        }

        .docTypeSelectBox {
            width: 160px;
            margin-bottom: 5px;
            display: table;

            .dx-texteditor-container {
                background-color: #fff;
                border: 1px silver solid;
                border-radius: 5px;
            }

            .dx-placeholder {
                margin-top: -11px;
                font-size: 0.9rem;
            }
        }
    }

    .fnContainer {
        margin-left: 14px;
        float: left;
        // firstname
        .docTypeTextFn {
            font-size: 0.8rem;
            float: left;
            margin-right: 10px;
            margin-top: 9px;
        }

        .firstNameTextBox {
            width: 160px;
            margin-bottom: 5px;
            display: table;

            .dx-texteditor-container {
                background-color: #fff;
                border: 1px silver solid;
                border-radius: 5px;
            }

            .dx-placeholder {
                margin-top: -11px;
                font-size: 0.9rem;
            }
        }
    }

    .lnContainer {
        margin-left: 14px;
        float: left;
        // lastname
        .docTypeTextLn {
            font-size: 0.8rem;
            float: left;
            margin-right: 10px;
            margin-top: 9px;
        }

        .lastNameTextBox {
            width: 160px;
            margin-bottom: 5px;
            display: table;

            .dx-texteditor-container {
                background-color: #fff;
                border: 1px silver solid;
                border-radius: 5px;
            }

            .dx-placeholder {
                margin-top: -11px;
                font-size: 0.9rem;
            }
        }
    }

    .payPeriodContainer {
        //float: left;
        //margin-left: 14px;
        //PayPeriod
        .payPeriodText {
            font-size: 0.8rem;
            float: left;
            margin-right: 10px;
            margin-top: 9px;
        }

        .payPeriodSelectBox {
            width: 160px;
            margin-bottom: 5px;
            display: table;

            .dx-texteditor-container {
                background-color: #fff;
                border: 1px silver solid;
                border-radius: 5px;
            }

            .dx-placeholder {
                margin-top: -11px;
                font-size: 0.9rem;
            }
        }
    }
}


.dx-dropdownlist-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
    padding: 8px 9px;
    font-size: 0.9rem;
}

.uploadOptions {
    margin-left: 5px;
    margin-top: 40px;
    font-size: 0.5rem !important;

    .chkDocType {
        margin-right: 20px;
    }

    .chkName {
        margin-right: 20px;
    }

    .chkPayPeriod {
    }
}

.browserNotOk {
    margin-top: 40px;
    background-color: #f8f8f8 !important;

}

@media (min-width: 2560px) {
    .docTypePanel {

        .docTypeContainer {
            .docTypeText {
            }

            .docTypeSelectBox {

                .dx-texteditor-container {
                }

                .dx-placeholder {
                }
            }
        }

        .fnContainer {
            // firstname
            .docTypeTextFn {
            }

            .firstNameTextBox {

                .dx-texteditor-container {
                }

                .dx-placeholder {
                }
            }
        }

        .lnContainer {
            // lastname
            .docTypeTextLn {
                /*   margin-left: 14px;*/
            }

            .lastNameTextBox {

                .dx-texteditor-container {
                }

                .dx-placeholder {
                }
            }
        }

        .payPeriodContainer {
            .payPeriodText {
                // margin-left: 14px;
                margin-left: 14px;
            }

            .payPeriodSelectBox {

                .dx-texteditor-container {
                }

                .dx-placeholder {
                }
            }
        }
    }
}

@media (min-width: 1920px) and (max-width: 2559px) {
    .docTypePanel {

        .docTypeContainer {
            .docTypeText {
            }

            .docTypeSelectBox {

                .dx-texteditor-container {
                }

                .dx-placeholder {
                }
            }
        }

        .fnContainer {
            // firstname
            .docTypeTextFn {
            }

            .firstNameTextBox {

                .dx-texteditor-container {
                }

                .dx-placeholder {
                }
            }
        }

        .lnContainer {
            // lastname
            .docTypeTextLn {
                /*margin-left: 14px;*/
            }

            .lastNameTextBox {

                .dx-texteditor-container {
                }

                .dx-placeholder {
                }
            }
        }

        .payPeriodContainer {
            .payPeriodText {
                // margin-left: 14px;
                margin-left: 14px;
            }

            .payPeriodSelectBox {

                .dx-texteditor-container {
                }

                .dx-placeholder {
                }
            }
        }
    }
}

@media (min-width: 1366px) and (max-width: 1919px) {
    .docTypePanel {

        .docTypeContainer {
            .docTypeText {
            }

            .docTypeSelectBox {

                .dx-texteditor-container {
                }

                .dx-placeholder {
                }
            }
        }

        .fnContainer {
            // firstname
            .docTypeTextFn {
            }

            .firstNameTextBox {

                .dx-texteditor-container {
                }

                .dx-placeholder {
                }
            }
        }

        .lnContainer {
            // lastname
            .docTypeTextLn {
                //margin-left: 14px;
                font-size: 0.8rem;
                margin-left: 5px;
            }

            .lastNameTextBox {

                .dx-texteditor-container {
                }

                .dx-placeholder {
                }
            }
        }

        .payPeriodContainer {
            .payPeriodText {
                margin-left: 14px;
            }

            .payPeriodSelectBox {

                .dx-texteditor-container {
                }

                .dx-placeholder {
                }
            }
        }
    }

   
}

@media (min-width: 768px) and (max-width: 1365px) {
    .docTypePanel {

        .docTypeContainer {
            .docTypeText {
                font-size: 0.8rem;
            }

            .docTypeSelectBox {
                width: 160px;

                .dx-texteditor-container {
                }

                .dx-placeholder {
                }
            }
        }

        .fnContainer {
            margin-left: 5px;
            float: none;
            // firstname
            .docTypeTextFn {
                font-size: 0.8rem;
            }

            .firstNameTextBox {
                width: 160px;

                .dx-texteditor-container {
                }

                .dx-placeholder {
                }
            }
        }

        .lnContainer {
            // lastname
            .docTypeTextLn {
                font-size: 0.8rem;
                margin-left: 5px;
            }

            .lastNameTextBox {
                width: 160px;

                .dx-texteditor-container {
                }

                .dx-placeholder {
                }
            }
        }

        .payPeriodContainer {
            .payPeriodText {
                font-size: 0.8rem;
            }

            .payPeriodSelectBox {
                width: 160px;

                .dx-texteditor-container {
                }

                .dx-placeholder {
                }
            }
        }
    }

    .dx-fileuploader-dragover .dx-fileuploader-input-container {
        height: 300px !important;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .docTypePanel {
        margin-bottom: 12px;

        .docTypeContainer {
            margin-left: 5px;
            float: left;
            //doc type
            .docTypeText {
                font-size: 0.7rem;
                float: left;
                margin-right: 10px;
                margin-top: 9px;
            }

            .docTypeSelectBox {
                width: 130px;
                margin-bottom: 5px;
                display: table;

                .dx-texteditor-container {
                    background-color: #fff;
                    border: 1px silver solid;
                    border-radius: 5px;
                }

                .dx-placeholder {
                    margin-top: -11px;
                    font-size: 0.9rem;
                }
            }
        }

        .fnContainer {
            margin-left: 5px;
            float: left;
            // firstname
            .docTypeTextFn {
                font-size: 0.7rem;
                float: left;
                margin-right: 10px;
                margin-top: 9px;
            }

            .firstNameTextBox {
                width: 130px;
                margin-bottom: 5px;
                display: table;

                .dx-texteditor-container {
                    background-color: #fff;
                    border: 1px silver solid;
                    border-radius: 5px;
                }

                .dx-placeholder {
                    margin-top: -11px;
                    font-size: 0.9rem;
                }
            }
        }

        .lnContainer {
            margin-left: 5px;
            // lastname
            .docTypeTextLn {
                font-size: 0.7rem;
                float: left;
                margin-right: 10px;
                margin-top: 9px;
            }

            .lastNameTextBox {
                width: 130px;
                margin-bottom: 5px;
                display: table;

                .dx-texteditor-container {
                    background-color: #fff;
                    border: 1px silver solid;
                    border-radius: 5px;
                }

                .dx-placeholder {
                    margin-top: -11px;
                    font-size: 0.9rem;
                }
            }
        }

        .payPeriodContainer {
            margin-left: 5px;
            float: left;
            //PayPeriod
            .payPeriodText {
                font-size: 0.7rem;
                float: left;
                margin-right: 10px;
                margin-top: 9px;
            }

            .payPeriodSelectBox {
                width: 130px;
                margin-bottom: 5px;
                display: table;

                .dx-texteditor-container {
                    background-color: #fff;
                    border: 1px silver solid;
                    border-radius: 5px;
                }

                .dx-placeholder {
                    margin-top: -11px;
                    font-size: 0.9rem;
                }
            }
        }
    }

    .dx-fileuploader-dragover .dx-fileuploader-input-container {
        height: 200px !important;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .docTypePanel {
        margin-bottom: 12px;

        .docTypeContainer {
            margin-left: 5px;
            float: left;
            //doc type
            .docTypeText {
                font-size: 0.8rem;
                float: left;
                margin-right: 10px;
                margin-top: 9px;
            }

            .docTypeSelectBox {
                width: 160px;
                margin-bottom: 5px;
                display: table;

                .dx-texteditor-container {
                    background-color: #fff;
                    border: 1px silver solid;
                    border-radius: 5px;
                }

                .dx-placeholder {
                    margin-top: -11px;
                    font-size: 0.9rem;
                }
            }
        }

        .fnContainer {
            margin-left: 5px;
            float: left;
            // firstname
            .docTypeTextFn {
                font-size: 0.8rem;
                float: left;
                margin-right: 10px;
                margin-top: 9px;
            }

            .firstNameTextBox {
                width: 160px;
                margin-bottom: 5px;
                display: table;

                .dx-texteditor-container {
                    background-color: #fff;
                    border: 1px silver solid;
                    border-radius: 5px;
                }

                .dx-placeholder {
                    margin-top: -11px;
                    font-size: 0.9rem;
                }
            }
        }

        .lnContainer {
            margin-left: 5px;
            // lastname
            .docTypeTextLn {
                font-size: 0.8rem;
                float: left;
                margin-right: 10px;
                margin-top: 9px;
            }

            .lastNameTextBox {
                width: 160px;
                margin-bottom: 5px;
                display: table;

                .dx-texteditor-container {
                    background-color: #fff;
                    border: 1px silver solid;
                    border-radius: 5px;
                }

                .dx-placeholder {
                    margin-top: -11px;
                    font-size: 0.9rem;
                }
            }
        }

        .payPeriodContainer {
            margin-left: 5px;
            //float: left;
            //PayPeriod
            .payPeriodText {
                font-size: 0.8rem;
                float: left;
                margin-right: 10px;
                margin-top: 9px;
            }

            .payPeriodSelectBox {
                width: 160px;
                margin-bottom: 5px;
                display: table;

                .dx-texteditor-container {
                    background-color: #fff;
                    border: 1px silver solid;
                    border-radius: 5px;
                }

                .dx-placeholder {
                    margin-top: -11px;
                    font-size: 0.9rem;
                }
            }
        }
    }

    
}


@import "../../dx-styles.scss";
@import "../../themes/generated/variables.additional.scss";

.side-navigation-menu {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
    width: 250px !important;

    .menu-container {
        min-height: 100%;
        display: flex;
        flex: 1;
        background-color: #F2F2F2;

        .dx-treeview {
            // ## Long text positioning
            white-space: nowrap;
            // ##
            // ## Icon width customization
            .dx-treeview-item {
                padding-left: 0;
                padding-right: 0;

                .dx-icon {
                    width: $side-panel-min-width !important;
                    margin: 0 !important;
                }
            }
            // ##
            // ## Arrow customization
            .dx-treeview-node {
                padding: 0 0 !important;
            }

            .dx-treeview-toggle-item-visibility {
                right: 10px;
                left: auto;
            }

            .dx-rtl .dx-treeview-toggle-item-visibility {
                left: 10px;
                right: auto;
            }
            // ##
            // ## Item levels customization
            .dx-treeview-node {
                &[aria-level='1'] {
                    font-weight: bold;
                    border-bottom: 1px solid $base-border-color;
                }

                &[aria-level='2'] .dx-treeview-item-content {
                    font-weight: normal;
                    padding: 0 $side-panel-min-width;
                }
            }
            // ##
        }
        // ## Selected & Focuced items customization
        .dx-treeview {
            .dx-treeview-node-container {
                .dx-treeview-node {
                    &.dx-state-selected:not(.dx-state-focused) > .dx-treeview-item {
                        background: transparent;
                    }

                    &.dx-state-selected > .dx-treeview-item * {
                        color: $base-accent;
                    }

                    &:not(.dx-state-focused) > .dx-treeview-item.dx-state-hover {
                        background-color: lighten($base-bg, 4.00);
                    }
                }
            }
        }

        .dx-theme-generic .dx-treeview {
            .dx-treeview-node-container .dx-treeview-node.dx-state-selected.dx-state-focused > .dx-treeview-item * {
                color: inherit;
            }
        }
        // ##
    }
}

.dx-drawer-overlap.pre-init-blink-fix {
  @import "../../utils/patches.scss";
  @include menu-pre-init-patch;
}

@media (min-width: 2560px) {
}

@media (min-width: 1920px) and (max-width: 2559px) {
}

@media (min-width: 1366px) and (max-width: 1919px) {
}

@media (min-width: 768px) and (max-width: 1365px) {
}

@media (min-width: 481px) and (max-width: 767px) {
    .dx-checkbox + .dx-treeview-node-container, .dx-treeview-node-container {
        margin-top:70px !important;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    
}
.cbPanel {
    
    text-align:center;

    .messageTitle {
        font-size:2.6em;
        font-weight:300;
        padding-top:10px;
        padding-bottom:40px;
        color: gray;
        white-space: normal !important;
    }

    .messageWarningText {
        font-size: 1.2em;
        padding-bottom: 40px;
    }
    .messageText {
    }
    .noBotIcon {
        max-width:300px;
    }

}

.iconPanel {
    text-align: center;
    padding:10px 10px;

    .browserIcon {
        max-height: 70px;
    }

    .browserName {
    }
}

.footerPanel {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 1.2em;
    color: gray;
}


@media (min-width: 2560px) {
}

@media (min-width: 1920px) and (max-width: 2559px) {
}

@media (min-width: 1366px) and (max-width: 1919px) {
}

@media (min-width: 768px) and (max-width: 1365px) {
    .cbPanel {
        text-align: center;

        .messageTitle {
            font-size: 2.1em;
            font-weight: 300;
            padding-top: 0px;
            padding-bottom: 30px;
            color: gray;
        }

        .messageWarningText {
            font-size: 1.1em;
            padding-bottom: 20px;
        }

        .messageText {
        }

        .noBotIcon {
            max-width: 200px;
        }
    }

    .iconPanel {
        text-align: center;
        padding: 10px 10px;

        .browserIcon {
            max-height: 50px;
        }

        .browserName {
        }
    }

    .footerPanel {
        margin-top: 40px;
        margin-bottom: 40px;
        text-align: center;
        font-size: 1.2em;
        color: gray;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .cbPanel {
        text-align: center;

        .messageTitle {
            font-size: 2.1em;
            font-weight: 300;
            padding-top: 0px;
            padding-bottom: 30px;
            color: gray;
        }

        .messageWarningText {
            font-size: 1.1em;
            padding-bottom: 20px;
        }

        .messageText {
        }

        .noBotIcon {
            max-width: 170px;
        }
    }

    .iconPanel {
        text-align: center;
        padding: 10px 10px;

        .browserIcon {
            max-height: 50px;
        }

        .browserName {
        }
    }

    .footerPanel {
        margin-top: 40px;
        margin-bottom: 40px;
        text-align: center;
        font-size: 1.2em;
        color: gray;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .cbPanel {
        text-align: center;

        .messageTitle {
            font-size: 2.1em;
            font-weight: 300;
            padding-top: 0px;
            padding-bottom: 30px;
            color: gray;
        }

        .messageWarningText {
            font-size: 1.1em;
            padding-bottom: 20px;
        }

        .messageText {
        }

        .noBotIcon {
            max-width: 170px;
        }
    }

    .iconPanel {
        text-align: center;
        padding: 10px 10px;

        .browserIcon {
            max-height: 50px;
        }

        .browserName {
        }
    }

    .footerPanel {
        margin-top: 40px;
        margin-bottom: 40px;
        text-align: center;
        font-size: 1.2em;
        color: gray;
    }
}